
//Update user form styles

.link-style-update-user {
	text-decoration: none;
	color: #ff4500;
	font-size: 00.75em;
	margin-top: 10px;
}



#input-style-update-user {
	background-color: black;
}

#btn-signup-nf {
	color:#ff4500;
	border-color:#ff4500;
}

#btn-signup-nf:hover {
	background-color: #ff4500;
	color: white;
}

//box styles for user update form 
#box-update-user {
    position: relative;
    width: 380px;
    height: 450px;
    border-radius: 8px;
    background: #1c1c1c;
    overflow: hidden;
	text-align: center;
	top: 10em;
}

//box animation css
#box-update-user::before{
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 380px;
    height: 420px;
    background: linear-gradient(0deg, transparent, 
    #ff4500, #ff4500);
    transform-origin: bottom right;
    animation: animate 6s linear infinite;
}

#box-update-user::after{
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 380px;
    height: 420px;
    background: linear-gradient(0deg, transparent, 
    #ff4500, #ff4500);
    transform-origin: bottom right;
    animation: animate 6s linear infinite;
    animation-delay: -3s;
}

@keyframes animate {
    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(360deg);
    }
}

//form inside box css 
#form-update-user {
    position: absolute;
    inset: 2px;
    border-radius: 8px;
    background: #28292d;
    z-index: 10;
    padding: 50px 40px;
    display: flex;
    flex-direction: column;
	align-items: center;
	
}
#form-update-user h2 {
    color: #ff4500;
    font-weight: 500;
    text-align: center;
    letter-spacing: 0,1em;

}