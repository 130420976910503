

.login-backdrop {
    background-image:linear-gradient(rgba(0, 0, 0, 0.9),rgba(0, 0, 0, 0.6)), url('../images/movie-poster-background.jpg');
    background-repeat: repeat;
    background-size:contain;
    width: 100%;
    width: 100vw;
    height: 100vh;
}


.forgot-pass {
	position: relative;
	left: 6.2em;
	top: 3px;
}


.link-style-login {
	text-decoration: none;
	color: #ff4500;
	font-size: 00.95em;
}



#input-style-login {
	background-color: black;
}

#btn-login-nf {
	color:#ff4500;
	border-color:#ff4500;
}

#btn-login-nf:hover {
	background-color: #ff4500;
	color: white;
}

//login form styles 
#box-login {
    position: relative;
    width: 380px;
    height: 420px;
    border-radius: 8px;
    background: #1c1c1c;
    overflow: hidden;
	text-align: center;
    bottom: 20px;
	
}

#box-login::before{
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 380px;
    height: 420px;
    background: linear-gradient(0deg, transparent, 
    #ff4500, #ff4500);
    transform-origin: bottom right;
    animation: animate 6s linear infinite;
}

#box-login::after{
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 380px;
    height: 420px;
    background: linear-gradient(0deg, transparent, 
    #ff4500, #ff4500);
    transform-origin: bottom right;
    animation: animate 6s linear infinite;
    animation-delay: -3s;
}

@keyframes animate {
    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(360deg);
    }
}

#form-login {
    position: absolute;
    inset: 2px;
    border-radius: 8px;
    background: #28292d;
    z-index: 10;
    padding: 50px 40px;
    display: flex;
    flex-direction: column;
	align-items: center;
	

}
#form-login h2 {
    color: #ff4500;
    font-weight: 500;
    text-align: center;
    letter-spacing: 0,1em;

}

@media (min-width: 575px) {
    .login-backdrop {
        background-image:linear-gradient(rgba(0, 0, 0, 1),rgba(0, 0, 0, 0.6)), url('../images/movie-poster-background.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        width: 100vw;
        height: 1000px;
    }

}
