span {
	color: white;
}

#logo {
	width: 400px;
	height: auto;
	position: absolute;
	position: relative;
	bottom: 15px;
}


.spinner {
	display: flex;
	justify-content: center;
}

@media (max-width: 576px) {
	#logo {
		width: 59%;
		height: auto;
	}
}
