
	//React player css
	.player-wrapper {
	position: relative;
	padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
	width: 100%;
	height: 100%;
	
 	 }

  #container {
	max-width: none;
  	}
  
	.react-player {
		position: absolute;
		top: -5%;
		left: 0;
		width: auto;
		height: auto;
		}


  //users favorites bookmark css
  .outline-bookmark, .full-bookmark {
	cursor: pointer;
	}
	.move-bookmark-mv {
	width: 3.2vw;
	height: auto;
	position: absolute;
	position: relative;
	top:0px;
	right: -4px;
	color: orangered;
	background-color: rgba(0,0,0,.2);
	}
	
	#similar-movies {
		display: flex;
		flex-wrap: wrap;
	}

  	//font style css
  .font-style-bold {
	font-family: 'Roboto', sans-serif;
	font-weight: 500;
	}

	.font-style {
	font-family: 'Roboto', sans-serif;
	}

	.font-style-i {
	font-style: italic;
	}

	//H2 heading styles
	.movie-description-heading {
		color: black;
		border-left:solid 9px #ff4500;
		padding-left: 8px;
	}

	//container for top section with gradient background
		#movie-view-section-top {
		width: 100vw;
		max-width: none;
		position: relative;
		left: 50vw;
		left: -50vw;
		margin-left: 50%;
		overflow: hidden;
		background: radial-gradient(circle at 10% 20%, rgb(0, 0, 0) 0%, rgb(91, 90, 90) 90.2%);
	}


	//styling for description, director, writers,actors and genres
	.movie-styles-name {
	color: black;
	font-size: 1em;
	}

	//white container that holds the bottom movie description 
	#movie-view-section-bottom {
		background-color: white;
		// height: 100%;
		width: 100vw;
		max-width: 100vw;
		position: relative;
		left: 50vw;
		left: -50vw;
		margin-left: 50%;
		overflow-x: hidden;
	}

	//grey container that holds content in bottom section 
	#movie-description-section-bottom {
		background: #F5f5f5;
		width: 100vw;
		max-width: 100vw;
		position: relative;
		left: 50vw;
		left: -50vw;
		margin-left: 50%;
	}

	//css for movie case poster
	#movie-image {
		display: none;
	}

	//css for release date, rating, and movie length
	.movie-stats {
		color: #BDBDBD;
		font-size: 0.9em;
		padding: 15px;
		position: absolute;
		position: relative;
		bottom: 12px;
	}


	//movie description styling
	.movie-description {
		color: black;
		font-size: 1.1em;
	}

	//movie title style
	.title-style {
		font-size: 4vw;
		padding-left: 15px;
	}



	// //media queries for all screen size responsiveness

	@media (min-width: 576px) {

		// #col-remove {
		// 	flex: none;
		// }

		// .col {
		// 	flex: none;
		// }

		#movie-image {
			display: block;
		position: relative;
		top: 35px;
		width: 100%;
		height: 100%;
	}
	}

	@media (min-width: 521px) and (max-width: 575px) {

		.title-style {
			font-size: 4rem;
		}

		#movie-image {
			display: none;
		}

	.player-wrapper {
		width: 120%;
		height: 120%;
		position: absolute;
		position: relative;
		bottom: -30px;
		right: 50px;
	}

	#movie-content {
		position: absolute;
		position: relative;
		bottom: 40px;
	}

	.movie-details {
		position: absolute;
		position: relative;
		top: 30px;
		right: 30px;
	}

	#movie-view-section-top {
				padding: 40px;
	
			}

			.col {
				flex: none;
			}


			.movie-stats {
						font-size: 12px;
				position: absolute;
				position: relative;
				// right: 55px;
				top: -21px;
			}	

				.move-bookmark-mv {
						position: absolute;
						position: relative;
						width: 10vw;
						top: -1px;
				}

				#col-remove {
				flex: none;
				}
	}

	@media (min-width: 484px) and (max-width: 520px) {

		.title-style {
			font-size: 3.5rem;
		}

			.movie-stats {
				font-size: 12px;
		position: absolute;
		position: relative;
		// right: 55px;
		top: -21px;
	}	

	.movie-details {
		position: absolute;
		position: relative;
		top: 27px;
		right: 30px;
	}

		#movie-view-section-top {
			padding: 40px;

		}

		.move-bookmark-mv {
			position: absolute;
			position: relative;
			width: 10vw;
			top: -1px;
	}

		#col-remove {
			flex: none;
			}

			.player-wrapper {
				width: 120%;
				height: 120%;
				position: absolute;
				position: relative;
				bottom: -30px;
				right: 38px;
			}
		
			#movie-content {
				position: absolute;
				position: relative;
				bottom: 25px;
			}

		
	}

	@media (min-width: 389px) and (max-width: 485px) {

		.title-style {
			font-size: 2rem;
		}

			.movie-stats {
				font-size: 12.5px;
		position: absolute;
		position: relative;
		// right: 55px;
		top: -18px;
	}	

	.movie-details {
		position: absolute;
		position: relative;
		top: 27px;
		right: 30px;
	}

	#movie-content {
		position: absolute;
		position: relative;
		bottom: 25px;
	}

	.move-bookmark-mv {
		position: absolute;
		position: relative;
		width: 10vw;
		top: -1px;
	}

		#movie-view-section-top {
			padding: 40px;

		}

		#col-remove {
			flex: none;
			}

	.player-wrapper {
		width: 120%;
		height: 120%;
		position: absolute;
		position: relative;
		bottom: -30px;
		right: 40px;
	}


	}

	@media (min-width: 390px) and (max-width: 440px) {

		.title-style {
			font-size: 1.7rem;
		}

			.movie-stats {
				font-size: 12.5px;
		position: absolute;
		position: relative;
		// right: 55px;
		top: -14px;
	}	

	.movie-details {
		position: absolute;
		position: relative;
		top: 17px;
		right: 30px;
	}

	#movie-content {
		position: absolute;
		position: relative;
		bottom: 20px;
	}

	.move-bookmark-mv {
		position: absolute;
		position: relative;
		width: 10vw;
		top: -1px;
	}

		.player-wrapper {
			width: 120%;
			height: 120%;
			position: absolute;
			position: relative;
			bottom: -30px;
			right: 35px;
		}
	}

	#col-remove {
		flex: none;
		}

	@media (min-width: 364px) and (max-width: 390px) {

		.title-style {
			font-size: 1.5rem;
		}

			.movie-stats {
				font-size: 10px;
		position: absolute;
		position: relative;
		// right: 55px;
		top: -14px;
	}	

	.movie-details {
		position: absolute;
		position: relative;
		top: 15px;
		right: 30px;
	}

	#movie-content {
		position: absolute;
		position: relative;
		bottom: 25px;
	}

	.move-bookmark-mv {
		position: absolute;
		position: relative;
		width: 10vw;
		top: -1px;
	}

		.player-wrapper {
			width: 120%;
			height: 120%;
			position: absolute;
			position: relative;
			bottom: -30px;
			right: 30px;
		}
	
		#movie-view-section-top {
			padding: 40px;
		}

		#col-remove {
			flex: none;
			}
	}

	@media (min-width: 339px) and (max-width: 364px) {

		.title-style {
			font-size: 1.4rem;
		}

			.movie-stats {
				font-size: 10px;
		position: absolute;
		position: relative;
		// right: 55px;
		top: -14px;
	}	

	.movie-details {
		position: absolute;
		position: relative;
		top: 20px;
		right: 30px;
	}

	#movie-content {
		position: absolute;
		position: relative;
		bottom: 25px;
	}

	.move-bookmark-mv {
		position: absolute;
		position: relative;
		width: 10vw;
		top: -1px;
	}

		.player-wrapper {
			width: 120%;
			height: 120%;
			position: absolute;
			position: relative;
			bottom: -30px;
			right: 30px;
		}
	
	
		.movie-details {
			position: absolute;
			position: relative;
			top: 20px;
		}

		#movie-view-section-top {
			padding: 40px;

		}

		#col-remove {
			flex: none;
			}
	}

	@media (min-width: 319px) and (max-width: 340px) {


		.title-style {
			font-size: 1.3rem;
		}

			.movie-stats {
				font-size: 10px;
		position: absolute;
		position: relative;
		// right: 55px;
		top: -14px;
	}	

	.movie-details {
		position: absolute;
		position: relative;
		top: 5px;
		right: 30px;
		transform: translate(0, 13px);
	}

	#movie-content {
		position: absolute;
		position: relative;
		bottom: 25px;
	}

	.move-bookmark-mv {
		position: absolute;
		position: relative;
		width: 10vw;
		top: -1px;
	}

		.player-wrapper {
			width: 130%;
			height: 120%;
			position: absolute;
			position: relative;
			bottom: -30px;
			right: 37px;
		}
	
		#movie-view-section-top {
			padding: 40px;

		}

		#col-remove {
			flex: none;
			}
	}
	

	@media (min-width: 309px) and (max-width: 320px) {

		.title-style {
			font-size: 1.1rem;
		}

			.movie-stats {
				font-size: 10px;
		position: absolute;
		position: relative;
		// right: 55px;
		top: -5px;
	}	

	.movie-details {
		position: absolute;
		position: relative;
		top: -1px;
		right: 30px;
		// transform: translate(0, 13px);
	}

	#movie-content {
		position: absolute;
		position: relative;
		bottom: 25px;
	}

	.move-bookmark-mv {
		position: absolute;
		position: relative;
		width: 10vw;
		top: -1px;
	}

		.player-wrapper {
			width: 130%;
			height: 120%;
			position: absolute;
			position: relative;
			bottom: -30px;
			right: 33px;
		}
	
		#movie-view-section-top {
			padding: 40px;

		}
	}

	@media (min-width: 302px) and (max-width: 310px) {

		.player-wrapper {
			width: 280%;
			height: 230%;
			position: absolute;
			position: relative;
			bottom: 05px;
			right: 170px;
		}

		#movie-view-section-top {
			padding: 40px;

		}

		#movie-content {
			position: absolute;
			position: relative;
			bottom: 35px;
			left: 18px;
		}

		.movie-stats {
			font-size: 0.8em;
			position: absolute;
			position: relative;
			bottom: 16px;
		}

		// #col-remove {
		// 	flex: none;
		// 	}
	}

	@media (min-width: 296px) and (max-width: 303px) {

		.player-wrapper {
			width: 280%;
			height: 230%;
			position: absolute;
			position: relative;
			bottom: 05px;
			right: 170px;
		}

		#movie-view-section-top {
			padding: 40px;

		}

		#movie-content {
			position: absolute;
			position: relative;
			bottom: 35px;
			left: 18px;
		}

		.movie-stats {
			font-size: 0.8em;
			position: absolute;
			position: relative;
			bottom: 16px;
		}
	}

	@media (min-width: 287px) and (max-width: 297px) {

		.player-wrapper {
			width: 280%;
			height: 230%;
			position: absolute;
			position: relative;
			bottom: 05px;
			right: 165px;
		}

		#movie-view-section-top {
			padding: 40px;

		}

		#movie-content {
			position: absolute;
			position: relative;
			bottom: 35px;
			left: 18px;
		}

		.movie-stats {
			font-size: 0.8em;
			position: absolute;
			position: relative;
			bottom: 16px;
		}
	}

	@media (min-width: 250px) and (max-width: 288px) {

		.player-wrapper {
			width: 333%;
			height: 270%;
			position: absolute;
			position: relative;
			bottom: 7px;
			right: 180px;
		}

		#movie-view-section-top {
			padding: 40px;

		}

		#movie-content {
			position: absolute;
			position: relative;
			bottom: 35px;
			left: 18px;
		}

		.movie-stats {
			font-size: 0.8em;
			position: absolute;
			position: relative;
			bottom: 16px;
		}
	}




	// @media (max-width: 767px) {

	// .move-bookmark-mv {
	// 	position: absolute;
	// 	position: relative;
	// 	width: 5vw;
	// 	top: 1px;
	// 	}

	// 	.movie-stats {
	// 		position: absolute;
	// 	position: relative;
	// 	// right: 55px;
	// 	top: -15px;
	// 	}

	// 	#movie-image {
	// 		position: absolute;
	// 		position: relative;
	// 		// right: 55px;
	// 		top: 5px;
	// 	}
	// }

	// @media (min-width: 575px) {

	// 	#movie-content {
	// 		position: absolute;
	// 		position: relative;
	// 		bottom: 40px;
	// 	}

	// 	#movie-view-section-top {
	// 		padding: 40px;

	// 	}
	// 	#movie-image {
			

	// 	}

	// 	.movie-stats {
	// 		font-size: 8px;
	// 		position: absolute;
	// 			position: relative;
	// 			right: 24px;
	// 			bottom: 10px;
	// 	}

	// 	.player-wrapper {
	// 		width: 210%;
	// 		height: 170%;
	// 		position: absolute;
	// 			position: relative;
	// 			bottom: 5px;
	// 			right: 270px;
	// 	}


	// 	.move-bookmark-mv {
	// 		position: absolute;
	// 			position: relative;
	// 			right: 15px;
	// 			top: 3px;
	// 			width: 5.8vw;			
	// 		}

	// 		.title-style {
	// 			position: absolute;
	// 			position: relative;
	// 			right: 24px;
	// 			top: 2px;
	// 		}	
	// }

	// @media (max-width: 540px) {

	// 	.player-wrapper {
	// 		width: 210%;
	// 		height: 180%;
	// 		position: absolute;
	// 			position: relative;
	// 			bottom: 6px;
	// 			right: 250px;
	// 	}

	// 	.movie-stats {
	// 		position: absolute;
	// 			position: relative;
	// 			right: 24px;
	// 			padding: 10px;
	// 	}
	// }

	// @media (max-width: 485px) {

	// 	.player-wrapper {
	// 		width: 220%;
	// 		height: 190%;
	// 		position: absolute;
	// 			position: relative;
	// 			bottom: 8.5px;
	// 			right: 225px;
	// 	}

	// 	.movie-stats {
	// 		position: absolute;
	// 			position: relative;
	// 			right: 24px;
	// 			padding: 10px;
	// 			top: -16px;
	// 	}

	// 	.move-bookmark-mv {
	// 		position: absolute;
	// 			position: relative;
	// 			right: 15px;
	// 			top: 0px;
	// 			width: 5vw;			
	// 		}
	// }

	// @media (max-width: 450px) {
	// 	.player-wrapper {
	// 		width: 220%;
	// 		height: 210%;
	// 		position: absolute;
	// 			position: relative;
	// 			bottom: 8.5px;
	// 			right: 213px;
	// 	}
	// }


	

	// @media (max-width: 430px) {

	// 	.player-wrapper {
	// 		width: 235%;
	// 		height: 210%;
	// 		position: absolute;
	// 			position: relative;
	// 			bottom: 10px;
	// 			right: 190px;
	// 	}
	// }

	// @media (max-width: 390px) {

	// 	.player-wrapper {
	// 		width: 260%;
	// 		height: 210%;
	// 		position: absolute;
	// 			position: relative;
	// 			bottom: 10px;
	// 			right: 195px;
	// 	}
	// }


	// @media (max-width: 376px) {
	// 	.player-wrapper {
	// 		width: 270%;
	// 		height: 240%;
	// 		position: absolute;
	// 			position: relative;
	// 			bottom: 10px;
	// 			right: 195px;
	// 	}

	// 	.movie-stats {
	// 		position: absolute;
	// 			position: relative;
	// 			right: 24px;
	// 			padding: 10px;
	// 			top: -17.9px;
	// 	}
	// }
	
	// @media (max-width: 370px) {
	// .player-wrapper {
	// 			width: 270%;
	// 			height: 240%;
	// 			position: absolute;
	// 				position: relative;
	// 				bottom: 13px;
	// 				right: 190px;
	// 		}

	// 		.movie-stats {
	// 			position: absolute;
	// 				position: relative;
	// 				right: 24px;
	// 				padding: 10px;
	// 				top: -18.5px;
	// 		}

	// 	}

	// @media (max-width: 360px) {

	// 	.player-wrapper {
	// 		width: 270%;
	// 		height: 240%;
	// 		position: absolute;
	// 			position: relative;
	// 			bottom: 12px;
	// 			right: 183px;
	// 	}
	// }

	// @media (max-width: 341px) {

	// 	.player-wrapper {
	// 		width: 270%;
	// 		height: 240%;
	// 		position: absolute;
	// 			position: relative;
	// 			bottom: 8px;
	// 			right: 175px;
	// 	}
	// }

	// @media (max-width: 327px) {

	// 	.player-wrapper {
	// 		width: 280%;
	// 		height: 240%;
	// 		position: absolute;
	// 			position: relative;
	// 			bottom: 8px;
	// 			right: 165px;
	// 	}
	// }


	// @media (max-width: 320px) {

	// 	.player-wrapper {
	// 		width: 285%;
	// 		position: absolute;
	// 			position: relative;
	// 			right: 161px;
	// 	}

	// 	@media (max-width: 315px) {

	// 		.player-wrapper {
	// 			width: 296%;
	// 			position: absolute;
	// 				position: relative;
	// 				right: 167px;
	// 		}

	// 		@media (max-width: 303px) {

	// 			.player-wrapper {
	// 				width: 305%;
	// 				position: absolute;
	// 					position: relative;
	// 					right: 164px;
	// 			}
	// 		}
	// 	}
	// }


