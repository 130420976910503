

.font-style {
	font-family: 'Roboto', sans-serif;
}

#btn-outline-nav {
	color: #ff4500;
	border-color: #ff4500;
	background-color: rgb(30, 30, 30);
}

#btn-outline-nav:hover {
	background-color: #ff4500;
	color: white;
}

a.nav-link {
	color: white;
	transition: 0.3s;
}
a.nav-link:hover {
	color: #ff4500;
}



