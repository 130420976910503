#box-forget-pass {
    position: relative;
    width: 380px;
    height: 450px;
    border-radius: 8px;
    background: #1c1c1c;
    overflow: hidden;
	text-align: center;
	top: 0em;
}


//box animation css
#box-forget-pass::before{
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 380px;
    height: 420px;
    background: linear-gradient(0deg, transparent, 
    #ff4500, #ff4500);
    transform-origin: bottom right;
    animation: animate 6s linear infinite;
}

#box-forget-pass::after{
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 380px;
    height: 420px;
    background: linear-gradient(0deg, transparent, 
    #ff4500, #ff4500);
    transform-origin: bottom right;
    animation: animate 6s linear infinite;
    animation-delay: -3s;
}

@keyframes animate {
    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(360deg);
    }
}
