

$primary: black;
$body-bg: Black;
$body-color: white;




@import '~bootstrap/scss/bootstrap.scss';
