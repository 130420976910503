.movie-featured-heading {
	color: white;
	border-left:solid 9px #ff4500;
	padding-left: 8px;
}

.font-style-bold {
	font-family: 'Roboto', sans-serif;
	font-weight: 500;
}

.movie-carousel {
    max-height: 250px;
	transition: 0.3s;
  margin-right: 10px;
  }
  
  .movie-carousel:hover {
    transform: scale(1.09);
  }

  .row-posters {
    display: flex;
    overflow-y: hidden;
    overflow-x: scroll;
    padding: 20px;
  }
  
  .row-posters::-webkit-scrollbar {
    display: none;
  }


  .left-arrow {
    display: none;
    position: absolute;
  }

  .right-arrow {
    position: absolute;
    display: none;
  }

  @media (min-width: 1669px) {
    .left-arrow {
      display: block;
      color: white;
      background-color: rgba(0,0,0,.3);
      position: absolute;
      top: 23%;
      left: 320px;
      z-index: 10;
      cursor: pointer;
      user-select: none;
  
    }
  
    .right-arrow {
      display: block;
      color:white;
      background-color: rgba(0,0,0,.3);
      position: absolute;
      top: 23%;
      right: 298px;
      z-index: 10;
      cursor: pointer;
      user-select: none
    }

  
}

#container-max {
  max-width: none;
}




  


